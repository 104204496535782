export const ageDropdown = () => {
  let ageList = [];
  for (let index = 0; index < 17; index++) {
    ageList.push({ text: index + 1, value: index + 1 });
  }
  return ageList;
};

export const getIcon = (category: string) => {
  switch (category) {
    case "Hotels":
      return "ic:outline-local-hotel";
    case "Locations":
      return "material-symbols:location-on-outline";
    case "Cities":
      return "icon-park-outline:city";
    default:
      return "material-symbols:quick-reference-all-outline";
  }
};
export const chipList = [
  { label: "All", icon: "" },
  { label: "Cities", icon: getIcon("Cities") },
  { label: "Hotels", icon: getIcon("Hotels") },
  { label: "Locations", icon: getIcon("Locations") },
];
