import { Icon } from "@iconify/react";
import React, { useState } from "react";
import InputForm from "src/components/FormComponents/inputForm";
import { getIcon, chipList } from "./config";
import dynamic from "next/dynamic";
// import { FullscreenOnMobile } from "src/components/FullscreenOnMobile";
const FullscreenOnMobile = dynamic(
  () =>
    import("src/components/FullscreenOnMobile").then(
      (mod) => mod.FullscreenOnMobile
    ),
  { ssr: false }
);
interface MobileHotelSearchProps {
  data: any;
  errors: any;
  hotelList: any;
  setisShown: any;
  isShown: boolean;
  handleChange: any;
  handleFilter: any;
  selectedChip: any;
  filteredChip: any;
  isLoading: boolean;
  availableFilterType: any;
}

export const MobileHotelSearch = ({
  data,
  isShown,
  errors,
  hotelList,
  setisShown,
  isLoading,
  handleFilter,
  handleChange,
  selectedChip,
  filteredChip,
  availableFilterType,
}: MobileHotelSearchProps) => {
  return (
    <FullscreenOnMobile
      className="relative one"
      content={
        <div className=" my-5">
          {isLoading && (
            <Icon
              icon="eos-icons:loading"
              width="24"
              height="24"
              // color="white"
              className="absolute top-[100px] right-8 "
            />
          )}
          <div className="px-8 mb-4 flex items-center gap-4  overflow-hidden overflow-x-scroll chips ">
            {availableFilterType.map((eachChip: any, eachChipIndex: number) => (
              <span
                onClick={() => handleFilter(eachChip.label)}
                key={eachChipIndex + 1 + ""}
                className={`px-3 py-1 ${eachChip.label === selectedChip
                  ? "bg-newPrimary-100/10 text-newPrimary-100 border-newPrimary-100"
                  : ""
                  } rounded-full  gap-2  text-sm flex items-center w-max cursor-pointer border-[1px] transition duration-300 ease`}
              >
                <Icon className="w-6 h-6" icon={getIcon(eachChip.label)} />
                <span className="text-light">{eachChip.label}</span>
              </span>
            ))}
          </div>
          {hotelList.length > 0 && (
            /* data.showHotelList &&  */ <ul className="!h-auto  overflow-y-auto  list-none bg-white px-[30px] rounded-xl cursor-pointer">
              {((filteredChip.length && filteredChip) || hotelList).map(
                (eachList: any, eachIndex: any) => (
                  <li
                    className="text-lg flex mb-5"
                    onClick={(e) => {
                      handleChange({
                        target: {
                          name: "hotel_id",
                          value: eachList.original._id ? eachList.value : "",
                          original: eachList.original,
                        },
                      });
                      setisShown(false);
                    }}
                    key={eachIndex + 1 + ""}
                  >
                    <span className="pr-3.5">
                      <Icon
                        className="w-5 h-5"
                        icon={getIcon(eachList.dataCat)}
                      />
                    </span>
                    {/* <span> {eachList.value}</span> */}
                    <span
                      // className="text location_text"
                      dangerouslySetInnerHTML={{
                        __html: eachList.text || eachList.value,
                      }}
                    />
                  </li>
                )
              )}
            </ul>
          )}
        </div>
      }
      header={
        <>
          <div className="flex">
            <strong className="m-auto">Search Hotels</strong>
            <button
              aria-label="Search Hotel"
              onClick={() => {
                setisShown(false);
              }}
            >
              <Icon icon="akar-icons:cross" />
            </button>
          </div>
          <div className="mt-5">
            <InputForm
              name="hotel"
              errors={errors}
              label="Location"
              showAutoFocus={isShown}
              showErrorLable={false}
              value={data.hotel || ""}
              handleChange={handleChange}
              placeholder={"Search Places, Hotels ...."}
              className={`pl-7`}
              iconClass={`top-[-2px] left-2`}
            />
          </div>
        </>
      }
      isShown={isShown}
      setisShown={setisShown}
    />
  );
};
