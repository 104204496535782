import { IconProps } from "@iconify/react";
import React, { ReactElement } from "react";
import { isMobile } from "react-device-detect";
import useShowRouteChangeProgress from "src/customHooks/useShowRouteChangeProgress";

interface IconButtonProps {
  handleSubmit: (event: any) => void;
  icon: ReactElement;
  text: string;
  disabled?: boolean;
  isHomePage?: any;
}

export const IconButton = ({
  handleSubmit,
  disabled,
  icon,
  text,
  isHomePage,
}: IconButtonProps) => {
  const [showLoader] = useShowRouteChangeProgress();
  return (
    <>
      <button
        disabled={showLoader || disabled}
        type="button"
        title={text}
        onClick={handleSubmit}
        className={`${
          !isHomePage ? " " : ""
        } flex items-center gap-2 px-8 py-4 font-medium !rounded-[4px]  !h-full text-sm lg:h-auto lg:text-base capitalize  text-white border-none lg:w-auto   lg:mt-0 w-full justify-center !bg-gradient-to-tr from-newSecondary-100 to-newPrimary-100 `}
      >
        <>
          {icon}
          <span className="hidden">{text}</span>
          <span className="text-lg font-semibold hidden [@media(min-width:1200px)]:block  ">
            Search{" "}
          </span>
        </>
      </button>
    </>
  );
};
