import SearchComponents from "./components/searchComponents";
import React, { useState, useEffect } from "react";
import { addDayInDate } from "utils/helperFunctions";
import { useRouter } from "next/router";
import { encodeData } from "utils/encodeDecode";
import dayjs from "dayjs";

interface Props {
  data?: any;
  isLoading: any;
  isHomePage?: any;
  isDetailsPage?: any;
  disabled?: boolean;
  hotelAvailableList: any;
  handleSearchSubmit?: any;
  getHotelListRequest: any;
  availableFilterType: any;
  handleSearchStaticHotel?: any;
}

const initialGuest = { adult: 2, childAges: [], rooms: 1, children: 0 };

const initialState = {
  flightData: {
    to: "",
    from: "",
    adults: 1,
    infants: 0,
    children: 0,
    cabin_type: "E",
    oneWay: false,
    totalPassengers: 9,
    onward: new Date(),
    showToList: false,
    showFromList: false,
    "return-date": addDayInDate(1, new Date()),
  },
  selected: "hotel",
  checkin: new Date(),
  guest: [initialGuest],
  deafultSelectedRoom: 0,
  showFromFlightList: false,
  showToFlightList: false,
  showGuestPopup: false,
  checkout: addDayInDate(1, new Date()),
};
const SearchHotelComponents = (props: Props) => {
  const {
    data,
    isLoading,
    disabled = false,
    isDetailsPage = false,
    isHomePage = false,
    getHotelListRequest,
    handleSearchSubmit,
    hotelAvailableList,
    availableFilterType,
    handleSearchStaticHotel,
  } = props;

  const router = useRouter();
  const [errors, seterrors] = useState<any>({});
  const [isStart, setisStart] = React.useState(true);

  const [searchData, setsearchData] = React.useState<any>(initialState);
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setsearchData(data);
    }
  }, [data]);
  const handleChange = (event: any) => {
    const { name, value, original = "" } = event.target;
    errors[name] && delete errors[name];
    /*   if (name === "showGuestPopup") {
      setsearchData({ ...searchData, showGuestPopup: value });
    } */
    switch (name) {
      case "showGuestPopup":
        setsearchData({ ...searchData, showGuestPopup: value });
        break;
      case "hotel_id":
        setsearchData({
          ...searchData,
          ["hotel"]: value,
          [name]: original._id,
          hotelDetails: original,
        });
        handleSearchStaticHotel({
          ...searchData,
          ["hotel"]: value,
          [name]: original._id,
          hotelDetails: original,
        });
        break;
      default:
        setsearchData({
          ...searchData,
          [name]: value,
        });
        getHotelListRequest(value);
        // dispatch(homepageActions.getHotelListReq(value));
        break;
    }
  };
  let arraydata = searchData;
  const handleDateChange = (
    name: string,
    date: any,
    alterOtherDate: string
  ) => {
    if (searchData[name] !== date) {
      arraydata = {
        ...arraydata,
        [name]: isStart
          ? date
          : dayjs(arraydata.checkin).isSame(dayjs(date), "day")
          ? arraydata.checkout
          : date,
        ...(alterOtherDate &&
          isStart && {
            [alterOtherDate]: addDayInDate(1, date),
          }),
      };
      setsearchData(arraydata);
    }
  };

  const handleGuestData = (
    name: string,
    index?: any,
    operation?: any,
    event?: any
  ) => {
    switch (name) {
      case "delete":
        const updatedArray = [
          ...searchData.guest.slice(0, index),
          ...searchData.guest.slice(index + 1, searchData.guest.length),
        ];
        setsearchData({
          ...searchData,
          guest: updatedArray,
          deafultSelectedRoom: updatedArray.length === 1 ? 0 : null,
        });
        break;
      case "edit" /* when user want to edit data */:
        setsearchData({ ...searchData, deafultSelectedRoom: index });
        break;
      case "adult": /*  when user increase or decrease the number from plus & minusß */
      case "children":
        let tempData = searchData.guest[index];
        let tempChildren = searchData.guest[index].childAges;
        if (operation) {
          tempData = {
            ...tempData,
            [name]:
              operation === "plus" ? tempData[name] + 1 : tempData[name] - 1,
            ...((name === "children" && {
              childAges:
                operation === "plus"
                  ? [...tempChildren, 1]
                  : tempChildren.slice(0, -1),
            }) ||
              []),
          };
        } else {
          if (event.target.value === "" || event.target.value) {
            const { value } = event.target;
            let deafultChildAge: any = [];
            const checkValue = () => {
              let number = 0;
              if (name === "adult") {
                return (number =
                  +value > 20 ? tempData[name] : +value < 1 ? 1 : +value);
              }
              if (name === "children") {
                for (
                  let index = 0;
                  index < (+value > 10 ? tempData[name] : +value);
                  index++
                ) {
                  deafultChildAge.push(tempChildren[index] || 1);
                }
                return (number = +value > 10 ? tempData[name] : +value);
              }
            };
            tempData = {
              ...tempData,
              [name]: checkValue(),
              ...((name === "children" && {
                childAges: deafultChildAge,
              }) ||
                []),
            };
          }
        }
        const updatedData = [
          ...searchData.guest.slice(0, index),
          tempData,
          ...searchData.guest.slice(index + 1, searchData.guest.length),
        ];
        setsearchData({ ...searchData, guest: updatedData });
        break;
      case "childAges" /* when user select children ages from dropdown */:
        const { value } = event.target;
        setsearchData({
          ...searchData,
          guest: [
            ...searchData.guest.slice(0, searchData.deafultSelectedRoom),
            {
              ...searchData.guest[searchData.deafultSelectedRoom],
              childAges: [
                ...searchData.guest[
                  searchData.deafultSelectedRoom
                ].childAges.slice(0, index),
                +value > 17
                  ? searchData.guest[searchData.deafultSelectedRoom].childAges[
                      index
                    ]
                  : +value,
                ...searchData.guest[
                  searchData.deafultSelectedRoom
                ].childAges.slice(
                  index + 1,
                  searchData.guest[searchData.deafultSelectedRoom].childAges
                    .length
                ),
              ],
            },
            ...searchData.guest.slice(
              searchData.deafultSelectedRoom + 1,
              searchData.guest.length
            ),
          ],
        });
        break;
      default: // add
        setsearchData({
          ...searchData,
          guest: [
            ...searchData.guest,
            { ...initialGuest, rooms: searchData.guest.length },
          ],
          deafultSelectedRoom: searchData.guest.length,
        });
        break;
    }
  };

  const validation = () => {
    let err: any = {};
    if (searchData.selected === "flight") {
      const data = searchData.flightData;
      if (!data.flight_from_id) err.from = "Please fill the form";
      if (!data.flight_to_id) err.to = "Please fill the form";
    } else {
      if (
        !router.pathname.startsWith("/hotel-details/") &&
        !searchData.hotel_id
      )
        err.hotel = "Please Select One";
    }
    return err;
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const error: any = validation();
    seterrors(error);
    if (Object.keys(error).length === 0) {
      if (isHomePage) {
        router.push({
          pathname: "/search-results/[slug]",
          query: { slug: encodeData(searchData) },
        });
      } else {
        handleSearchSubmit(searchData);
      }
    }
  };
  return (
    <SearchComponents
      errors={errors}
      data={searchData}
      isStart={isStart}
      disabled={disabled}
      isLoading={isLoading}
      isHomePage={isHomePage}
      setisStart={setisStart}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isDetailsPage={isDetailsPage}
      hotelList={hotelAvailableList}
      handleGuestData={handleGuestData}
      handleDateChange={handleDateChange}
      availableFilterType={availableFilterType}
    />
  );
};

export default SearchHotelComponents;
